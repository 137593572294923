import styled from 'styled-components'
import carouselArrow from '../../../images/next-arrow.svg'

export const TestimonialCarouselSection = styled.section`
  padding: 5rem 0;
  margin: 4rem 0 10rem 0;
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #f7f7f7 100%);

  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }

  .slick-slider {
    margin-bottom: 6rem;
  }

  .slick-slide > div > div {
    &:focus {
      outline: none;
    }
  }

  .slick-slide:nth-child(even) {
    margin-top: 3rem;
  }

  .slick-list {
    height: 480px;
  }

  @media screen and (max-width: 795px) {
    margin-bottom: 2rem;

    .slick-dots {
      bottom: -10px;
    }

    .slick-dots li button:before {
      font-size: 12px !important;
    }

    .slick-dots li.slick-active button:before {
      color: ${({ theme }) => theme.colors.vividBlue} !important;
      opacity: 1 !important;
    }

    .slick-dots li button {
      color: ${({ theme }) => theme.colors.veryLightGray};
      &::before {
        color: ${({ theme }) => theme.colors.grey};
      }
    }
    .slick-dots li button:focus:before {
      opacity: 0.25;
    }
    .slick-dots li.slick-active button:focus:before,
    .slick-dots li button:hover:before {
      opacity: 1;
    }

    .slick-slide:nth-child(even) {
      margin-top: 0rem;
    }
  }

  @media screen and (max-width: 425px) {
    padding: 2rem 0;
  }

  @media screen and (max-width: 375px) {
    .slick-slide {
      width: 320px !important;
    }
  }
`

export const NextArrow = styled.button`
  position: absolute;
  top: -125px;
  right: 0;
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-image: url(${carouselArrow});
  transform: rotate(180deg);
  background-size: 8px;
  background-position: 14px;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  & :hover {
    opacity: 1;
  }

  @media screen and (max-width: 795px) {
    display: none;
  }
`

export const PrevArrow = styled.button`
  position: absolute;
  top: -125px;
  right: 70px;
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-image: url(${carouselArrow});
  background-size: 8px;
  background-position: 14px;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  & :hover {
    opacity: 1;
  }

  @media screen and (max-width: 795px) {
    display: none;
  }
`

export const CarouselTitle = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  margin-bottom: 6rem;

  @media screen and (max-width: 795px) {
    text-align: center;
    font-size: 2.5rem;
    line-height: 3rem;
    width: 70%;
    margin: 0 auto 6rem auto;
  }
`
export const CarouselCard = styled.div`
  display: flex;
  width: 320px;
  height: 400px;
  background: ${({ theme }) => theme.colors.pureWhite};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 1rem;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

    & .carousel-image {
      transform: scale(1.05);
      backface-visibility: hidden;
    }
  }
  @media screen and (max-width: 795px) {
    margin: 1rem;
  }
  @media screen and (max-width: 375px) {
    width: 285px;
  }
`

export const CarouselCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.85rem 2.14rem;
  overflow: hidden;
`

export const CarouselCardName = styled.div`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.28rem;
  font-weight: bold;
  line-height: 1.42rem;
  margin-bottom: 0.7rem;
`

export const CarouselCardText = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  font-weight: normal;
  line-height: inherit;
  margin-bottom: auto;

  a {
    color: ${({ theme }) => theme.colors.vividBlue};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const CarouselCardProperty = styled.span`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1;
  opacity: 0.6;
`
