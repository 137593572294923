import React from 'react'
import PropTypes from 'prop-types'

const CheckedIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
      <path d="M2 6.737L6 12 16 2" stroke={color} strokeWidth={3} />
    </svg>
  )
}

CheckedIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
}

CheckedIcon.defaultProps = {
  width: 18,
  height: 15,
  color: '#1D9ED9'
}

export default CheckedIcon
