import styled from 'styled-components'

export const PmsSection = styled.section`
  padding: 15rem 0 0;
  overflow: hidden;

  @media (max-width: 425px) {
    padding: 3rem 0 0;
  }
`

export const PmsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  text-align: center;
  margin-bottom: 3rem;
  width: 80%;

  @media (max-width: 425px) {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: normal;
    width: 90%;
  }
`
export const PmsFeatureWrapper = styled.div`
  margin: 10rem 0;

  @media (max-width: 425px) {
    margin: 1rem 0;
  }
`
export const AutomatisationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 8rem 0;

  @media screen and (max-width: 1078px) {
    flex-direction: column;
  }

  @media screen and (max-width: 425px) {
    padding: 5rem 0;
  }
`
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 40%;
`

export const AutomatisationTitle = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 1078px) {
    text-align: center;
  }

  @media (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`
export const AutomatisationText = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: 1.71rem;
  opacity: 0.8;

  @media screen and (max-width: 1078px) {
    text-align: center;
    margin-bottom: 5rem;
  }
`

export const GradientWrapper = styled.div`
  background: radial-gradient(90.28% 119.86% at 92.64% 106.63%, #ed1e79 0%, #20629c 100%);
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 10px;
  flex-basis: 50%;
  padding: 6.57rem 5rem;

  ul li {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.pureWhite};
    font-size: 1.14rem;
    font-weight: 500;
    line-height: 2.14rem;
    padding: 1rem 0;

    svg {
      margin-right: 3rem;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 2.57rem 2rem;

    ul li {
      font-size: 0.9rem;
      line-height: 1.5rem;
      svg {
        margin-right: 1rem;
      }
    }
  }
`
