import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { usePmsImages } from '../../graphql/usePmsImagesHook'
import { LanguageContext } from '../Layout'

import {
  PmsSection,
  PmsWrapper,
  Title,
  PmsFeatureWrapper,
  AutomatisationWrapper,
  Description,
  AutomatisationTitle,
  AutomatisationText,
  GradientWrapper
} from './styles'
import Container from '../UI/Container'

import KeyFeatureBox from '../KeyFeatures/KeyFeatureBox'
import CheckedIcon from '../UI/Icons/Checked'

const PmsContent = ({ content }) => {
  const { content_title_pms, content_features_pms } = content
  const {
    PmsCalendar,
    PmsCalendarItem,
    PmsStatistics,
    PmsStatsPopup,
    PmsInovoice,
    PmsInovoiceTop,
    PmsDashboard,
    PmsDashboardTop,
    PmsFastCheck,
    PmsFastCheckTop
  } = usePmsImages()

  const { i18n } = useContext(LanguageContext)

  return (
    <PmsSection>
      <Container>
        <PmsWrapper>
          <Title>{content_title_pms}</Title>
        </PmsWrapper>
        <PmsFeatureWrapper>
          <KeyFeatureBox
            parallaxParams={{
              imageOne: {
                min: '0',
                max: '100',
                speed: '2',
                axis: 'y'
              }
            }}
            parallaxClass="calendar"
            direction="row"
            title={content_features_pms[0].title}
            text={content_features_pms[0].description}
            imageOne={PmsCalendar.childImageSharp.fluid}
            imageTwo={PmsCalendarItem.childImageSharp.fluid}
            top="33"
            left="-8"
            width="300"
            deg="270"
            noGradient
          />
          <KeyFeatureBox
            parallaxParams={{
              imageOne: {
                min: '0',
                max: '100',
                speed: '2',
                axis: 'y'
              }
            }}
            parallaxClass="statistics"
            direction="row-reverse"
            title={content_features_pms[1].title}
            text={content_features_pms[1].description}
            imageOne={PmsStatistics.childImageSharp.fluid}
            imageTwo={PmsStatsPopup.childImageSharp.fluid}
            top="20"
            left="55"
            deg="-270"
            width="300"
            noGradient
          />
          <KeyFeatureBox
            parallaxParams={{
              imageOne: {
                min: '0',
                max: '100',
                speed: '2',
                axis: 'y'
              }
            }}
            parallaxClass="invoice"
            direction="row"
            title={content_features_pms[2].title}
            text={content_features_pms[2].description}
            imageOne={PmsInovoice.childImageSharp.fluid}
            imageTwo={PmsInovoiceTop.childImageSharp.fluid}
            top="19"
            left="-6"
            deg="270"
            width="500"
            noGradient
          />
          <KeyFeatureBox
            parallaxParams={{
              imageOne: {
                min: '0',
                max: '100',
                speed: '2',
                axis: 'y'
              }
            }}
            parallaxClass="fast-check"
            direction="row-reverse"
            title={content_features_pms[3].title}
            text={content_features_pms[3].description}
            imageOne={PmsFastCheck.childImageSharp.fluid}
            imageTwo={PmsFastCheckTop.childImageSharp.fluid}
            top="14"
            left="5"
            width="600"
          />
          <KeyFeatureBox
            parallaxParams={{
              imageOne: {
                min: '0',
                max: '100',
                speed: '2',
                axis: 'y'
              }
            }}
            parallaxClass="dashboard"
            direction="row"
            title={content_features_pms[4].title}
            text={content_features_pms[4].description}
            imageOne={PmsDashboard.childImageSharp.fluid}
            imageTwo={PmsDashboardTop.childImageSharp.fluid}
            top="33"
            left="-8"
            width="500"
            deg="270"
            noGradient
          />
          <AutomatisationWrapper>
            <Description>
              <AutomatisationTitle dangerouslySetInnerHTML={{ __html: content_features_pms[5].title }} />
              <AutomatisationText dangerouslySetInnerHTML={{ __html: content_features_pms[5].description }} />
            </Description>
            <GradientWrapper>
              <ul>
                <li>
                  {' '}
                  <CheckedIcon color={'#F7F7F7'} />
                  {i18n.automatisation.email}
                </li>
                <li>
                  {' '}
                  <CheckedIcon color={'#F7F7F7'} />
                  {i18n.automatisation.creditCard}
                </li>
                <li>
                  {' '}
                  <CheckedIcon color={'#F7F7F7'} />
                  {i18n.automatisation.claim}
                </li>
                <li>
                  {' '}
                  <CheckedIcon color={'#F7F7F7'} />
                  {i18n.automatisation.price}
                </li>
                <li>
                  <CheckedIcon color={'#F7F7F7'} />
                  {i18n.automatisation.rooms}
                </li>
              </ul>
            </GradientWrapper>
          </AutomatisationWrapper>
        </PmsFeatureWrapper>
      </Container>
    </PmsSection>
  )
}

export default PmsContent

PmsContent.propTypes = {
  content: PropTypes.shape({
    content_title_pms: PropTypes.string,
    content_features_pms: PropTypes.arrayOf(PropTypes.object)
  })
}
