import styled from 'styled-components'

export const KeyFeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  align-items: center;
  padding: 8rem 0;

  @media screen and (max-width: 1078px) {
    flex-direction: column;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 0;
  }
`
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 40%;

  .booking-engine-btn {
    align-self: flex-start;
    margin-top: 2.35rem;
  }
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 1078px) {
    text-align: left;
  }

  @media screen and (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.3rem;
  }
`
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  opacity: 0.8;

  @media screen and (max-width: 1078px) {
    text-align: left;
    margin-bottom: 5rem;
  }
`

export const ImagesWrapper = styled.div`
  position: relative;
  width: 580px;
  height: 500px;

  @media screen and (max-width: 475px) {
    width: 95%;
    height: 200px;
    margin-bottom: 7rem;
  }

  @media screen and (min-width: 476px) and (max-width: 625px) {
    width: 80%;
    height: 300px;
    margin-bottom: 7rem;
  }
`

export const ImageContainer = styled.figure`
  position: ${({ position }) => position};
  background-repeat: no-repeat;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  max-height: 100%;
  top: ${({ top }) => (top ? `${top}%` : '0%')};
  left: ${({ left }) => (left ? `${left}%` : '0%')};

  img {
    border-radius: 10px;
  }

  & ::after {
    content: '';
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    min-height: 100%;
    width: 100%;
    z-index: 1;

    ${({ noGradient, deg }) =>
      !noGradient
        ? `background-image: linear-gradient(${deg}deg, #ffffff 3.62%, rgba(255, 255, 255, 0) 64.34%);`
        : `background-image: none;`}
  }

  @media screen and (max-width: 768px) {
    width: ${({ width }) => (width ? `58%` : '100%')};
  }
`
