import { useStaticQuery, graphql } from 'gatsby'

export const usePmsImages = () => {
  const images = useStaticQuery(graphql`
    fragment PmsFluidImage on File {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      PmsIntro: file(relativePath: { eq: "images/pms-illustration.png" }) {
        ...PmsFluidImage
      }
      PmsCalendar: file(relativePath: { eq: "images/calendar-back.jpg" }) {
        ...PmsFluidImage
      }
      PmsCalendarItem: file(relativePath: { eq: "images/calendar-front.png" }) {
        ...PmsFluidImage
      }
      PmsStatistics: file(relativePath: { eq: "images/statistics-back.jpg" }) {
        ...PmsFluidImage
      }
      PmsStatsPopup: file(relativePath: { eq: "images/statistics-front.png" }) {
        ...PmsFluidImage
      }
      PmsInovoice: file(relativePath: { eq: "images/invoice-back.jpg" }) {
        ...PmsFluidImage
      }
      PmsInovoiceTop: file(relativePath: { eq: "images/invoice-front.png" }) {
        ...PmsFluidImage
      }
      PmsFastCheck: file(relativePath: { eq: "images/gosti-pozadina.jpg" }) {
        ...PmsFluidImage
      }
      PmsFastCheckTop: file(relativePath: { eq: "images/gosti-modal.png" }) {
        ...PmsFluidImage
      }
      PmsDashboard: file(relativePath: { eq: "images/dashboard-back.jpg" }) {
        ...PmsFluidImage
      }
      PmsDashboardTop: file(relativePath: { eq: "images/dashboard-front.png" }) {
        ...PmsFluidImage
      }
    }
  `)

  return images
}
