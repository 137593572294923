import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Intro from 'components/Intro'
import PmsContent from '../components/Property-management-system'
import CallToAction from 'components/CTA'
import TestimonialCarousel from 'components/UI/Testimonial-carousel'

import { usePmsImages } from '../graphql/usePmsImagesHook'
const MainContent = styled.main`
  background-color: #ffffff;
`

const PropertyManagementSystem = ({ data, pageContext, location }) => {
  const { PmsIntro } = usePmsImages()

  const {
    pms: {
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: { intro_title_pms, subtitle_pms, content_title_pms, content_features_pms, testimonials_pms, cta_pms }
    }
  } = data

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <Header mode="light" inverted="true" locale={pageContext.locale} color={'#531564'} location={location} />
      <Intro
        title={intro_title_pms}
        subtitle={subtitle_pms}
        introImg={PmsIntro.childImageSharp.fluid}
        pageName="pms"
        yAxis={21}
      />
      <MainContent>
        <PmsContent content={{ content_title_pms, content_features_pms }} />
        <TestimonialCarousel testimonials={testimonials_pms} />
        <CallToAction data={cta_pms} locale={pageContext.locale} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default PropertyManagementSystem

export const query = graphql`
  query($id: Int, $locale: String!) {
    pms: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        intro_title_pms
        subtitle_pms
        content_title_pms
        content_features_pms {
          title
          description
        }
        testimonials_pms {
          description
          name
          property
        }
        cta_pms {
          title
          description
        }
      }
    }
  }
`
