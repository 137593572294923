import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { LocaleConsumer } from '../../Layout'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  TestimonialCarouselSection,
  NextArrow,
  PrevArrow,
  CarouselTitle,
  CarouselCard,
  CarouselCardContent,
  CarouselCardName,
  CarouselCardProperty,
  CarouselCardText
} from './styles'

import Container from '../Container'

const TestimonialCarousel = ({ testimonials }) => {
  const CustomNextArrow = (props) => {
    const { onClick } = props
    return <NextArrow onClick={onClick} aria-label="Next" />
  }

  const CustomPrevArrow = (props) => {
    const { onClick } = props
    return <PrevArrow onClick={onClick} aria-label="Previous" />
  }

  const settings = {
    variableWidth: true,
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 795,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  }
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <TestimonialCarouselSection>
          <Container>
            <CarouselTitle>{i18n.storyCarouselTitle}</CarouselTitle>
            <Slider {...settings}>
              {testimonials.map((t, i) => {
                return (
                  <div style={{ width: 400 }} key={`${t.name}-${i}`}>
                    <CarouselCard>
                      <CarouselCardContent>
                        <CarouselCardText dangerouslySetInnerHTML={{ __html: t.description }} />
                        <CarouselCardName>{t.name}</CarouselCardName>
                        <CarouselCardProperty>{t.property}</CarouselCardProperty>
                      </CarouselCardContent>
                    </CarouselCard>
                  </div>
                )
              })}
            </Slider>
          </Container>
        </TestimonialCarouselSection>
      )}
    </LocaleConsumer>
  )
}

export default TestimonialCarousel

TestimonialCarousel.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object)
}
