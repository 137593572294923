import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Rellax from 'rellax'

import { KeyFeatureContainer, Description, Title, Text, ImagesWrapper, ImageContainer } from './styles'
import Button from '../../Button'
import { LanguageContext } from '../../Layout'

import { handleTrackedBookingEngineExamplesClick } from 'helper/url'
import {useMixpanel} from "gatsby-plugin-mixpanel";

const KeyFeatureBox = ({ title, text, imageOne, imageTwo, direction, ...otherProps }) => {
  const { i18n } = useContext(LanguageContext)

  useEffect(() => {
    if (otherProps.parallaxClass) {
      const rellax = new Rellax(`.${otherProps.parallaxClass}`, {
        center: true,
        breakpoints: [576, 768, 1024]
      })
    }
  }, [otherProps.parallaxClass])

  const mixpanel = useMixpanel()

  return (
    <KeyFeatureContainer direction={direction}>
      <Description>
        <Title>{title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: text }} />
        {otherProps.beActive && (
          <Button
              type="large"
              href={otherProps.beLink}
              target="_blank"
              rel="noreferrer"
              className="booking-engine-btn"
              onClick={(e) => handleTrackedBookingEngineExamplesClick(e, otherProps.beLink, '', mixpanel, 'booking_engine_preview_initiated', otherProps.origin)}
          >
            {i18n.livePreview}
          </Button>
        )}
      </Description>
      <ImagesWrapper>
        {imageOne && (
          <ImageContainer
            position="absolute"
            width={otherProps.widthImageOne}
            deg={otherProps.deg}
            top={otherProps.imageOneTop}
          >
            <Img fluid={imageOne} />
          </ImageContainer>
        )}
        {imageTwo && (
          <ImageContainer
            className={otherProps.parallaxClass}
            position="absolute"
            deg={otherProps.deg}
            top={otherProps.top}
            left={otherProps.left}
            width={otherProps.width}
            noGradient={otherProps.noGradient}
            data-rellax-min-y={otherProps.parallaxParams ? otherProps.parallaxParams.imageOne.min : null}
            data-rellax-max-y={otherProps.parallaxParams ? otherProps.parallaxParams.imageOne.max : null}
            data-rellax-vertical-speed={otherProps.parallaxParams ? otherProps.parallaxParams.imageOne.speed : null}
            data-rellax-mobile-speed="-5"
            data-rellax-vertical-scroll-axis={
              otherProps.parallaxParams ? otherProps.parallaxParams.imageOne.axis : null
            }
          >
            <Img fluid={imageTwo} />
          </ImageContainer>
        )}
      </ImagesWrapper>
    </KeyFeatureContainer>
  )
}

export default KeyFeatureBox

KeyFeatureBox.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imageOne: PropTypes.object,
  imageTwo: PropTypes.object,
  direction: PropTypes.string,
  otherProps: PropTypes.shape({
    deg: PropTypes.string,
    top: PropTypes.string,
    left: PropTypes.string,
    width: PropTypes.string,
    noGradient: PropTypes.bool
  })
}
